import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
const icons = {
  complete: 'las la-check',
  cancel: 'las ls-times-circle',
  close: 'las la-times',
  delete: 'las la-times', // delete (e.g. v-chip close)
  clear: 'las la-times',
  success: 'las la-check-circle',
  info: 'las la-info-circle',
  warning: 'las la-exclamation-circle',
  error: 'las la-times',
  prev: 'las la-angle-left',
  next: 'las la-angle-right',
  checkboxOn: 'las la-check-square',
  checkboxOff: 'las la-stop',
  checkboxIndeterminate: 'las la-minus-square',
  delimiter: 'las la-circle', // for carousel
  sort: 'las la-arrow-up',
  expand: 'las la-chevron-down',
  menu: 'las la-bars',
  subgroup: 'las la-angle-down',
  dropdown: 'las la-angle-down',
  radioOn: 'las la-check-circle',
  radioOff: 'las la-circle',
  edit: 'las la-edit',
  ratingEmpty: 'las la-frown',
  ratingFull: 'las la-smile',
  ratingHalf: 'las la-meh',
  loading: 'las la-spinner',
  first: 'las la-step-backward',
  last: 'las la-step-forward',
  unfold: 'las la-arrows-alt-v',
  file: 'las la-file-upload',
  plus: 'las la-plus',
  minus: 'las la-minus',
};

export default new Vuetify({
  icons: {
    //iconfont: 'fa',
    values: icons,
  },
});
