import State from "deep-state-observer";

const initialState = {
  request: {
    url: {
      api: "/api",
      login: "/user",
      licenses: "/api/admin-licenses",
      statistics: "/api/statistics",
      payments: "/api/payments",
      errors: "/api/errors",
      "error-log": "/api/error-log",
      "blocked-domains": "/api/blocked-domains",
      "blocked-domains-unblock": "/api/blocked-domains/unblock",
    },
    params: {
      api: {
        get: {
          method: "GET",
        },
        post: {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
        put: {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
        delete: {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      },
      login: {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      },
    },
  },
};

const state = new State(initialState);

export default state;
