import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import state from '../State';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../Login.vue'),
  },
  {
    path: '/',
    name: 'App',
    component: () => import('../App.vue'),
    children: [
      {
        path: '/statistics',
        name: 'Statistics',
        component: () => import('../views/Statistics.vue'),
      },
      {
        path: '/licenses',
        name: 'Licenses',
        component: () => import('../views/Licenses/Licenses.vue'),
      },
      {
        path: '/payments',
        name: 'Payments',
        component: () => import('../views/Payments/Payments.vue'),
      },
      {
        path: '/errors',
        name: 'Errors',
        component: () => import('../views/Errors/Errors.vue'),
      },
    ],
  },
  {
    path: '*',
    component: () => import('../views/Error404.vue'),
  },
];

const router = new VueRouter({
  routes,
});

// user guard
router.beforeEach((to, from, next) => {
  if (to.fullPath !== '/login' && !state.get('user')) {
    return next('/login');
  }
  next();
});

export default router;
